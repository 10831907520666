export const anchors = [
  'Introduction',
  'Token',
  'Backers',
  'History',
  'Traction',
  'Engineering',
  'Community',
  'Team',
  'Comparison',
  'Roadmap',
];
